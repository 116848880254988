<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Add Video
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Add Video</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="name"
                label="Video Name"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <upload-file @uploaded="uploaded"></upload-file>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="url"
                label="Video URL"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="addVideo(); isDialogVisible = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'
import UploadFile from './UploadFile.vue'

export default {
  components: {
    UploadFile,
  },
  data() {
    return {
      name: '',
      url: '',
    }
  },
  methods: {
    uploaded(media) {
      const vm = this
      vm.url = media.url
    },
    addVideo() {
      const vm = this
      const data = {
        Name: vm.name,
        Url: vm.url,
      }

      function reload() {
        window.location.reload()
      }

      api.addVideo(data, reload)
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
