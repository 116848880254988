<template>
  <v-row>
    <v-col cols="12">
      <video-add></video-add>
      <video-list class="mt-4"></video-list>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

import VideoAdd from './VideoAdd.vue'
import VideoList from './VideoList.vue'

export default {
  components: {
    VideoAdd,
    VideoList,
  },
}
</script>
