<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="ID"
      class="table-rounded"
      hide-default-footer
      disable-sort
    >
      <template #[`item.id`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.VideoID }}</span>
          <small>{{ item.VideoID }}</small>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import api from '../../api'

export default {
  components: {

  },
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    const vm = this
    function setVideo(campaigns) {
      vm.items = campaigns
    }
    api.videos(setVideo)
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      active: 'primary',
      pause: 'success',
      Rejected: 'error',
      inactive: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'ID', value: 'VideoID' },
        { text: 'Name', value: 'Name' },
        { text: 'URL', value: 'URL' },
      ],
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>
